<template>
  <section class="xa-container xa-container--hasFoot">
    <template v-if="!isLoading">
      <div class="xa-cell__box">故障情况补充说明</div>
      <div class="xa-cell__box xa-bg-white">
        <textarea
          v-model="description"
          rows="5"
          placeholder="请输入"
        ></textarea>
      </div>
      <div class="xa-cell__box">故障情况图片</div>
      <div class="xa-cell__box xa-bg-white">
        <van-uploader :after-read="afterRead" v-model="images" multiple/>
      </div>
      <EnsureFootBtn :disabled="submitBtnDisabled" @submit="onSubmit" />
    </template>
  </section>
</template>
<script>
import basePage from '@/mixins/basePage'
import uploadMix from '@/mixins/upload'
import { isApp } from '@/controllers/app-android'
import EnsureFootBtn from '@/components/EnsureFootBtn'
import {networkFeedback} from '@/apis/authas'
/**
 * 用于临时存储数据，
 * 在跳转到定损物料页面时候，临时存储下已经填入的表单信息
 * 可以用sessionStorage实现，只是在app中不能刷新页面，所以简单实现
 */
const pageStorage = {}
function getPageStorage(key) {
  const value = pageStorage[key]
  pageStorage[key] = null
  return value
}

export default {
  mixins: [basePage, uploadMix],
  components: {
    EnsureFootBtn,
  },
  config: {
    title: '提交反馈信息'
  },
  data() {
    return {
      isApp,
      isLoading: true,
      showInputPopup: false,
      description: '',
      images: [
        // {
        //   url: 'https://img.yzcdn.cn/vant/leaf.jpg',
        //   status: 200,
        //   message: '上传成功'
        // }
      ],
      device: null,
    }
  },
  computed: {
    submitBtnDisabled() {
      return false
    }
  },
  methods: {
    canContinueCheckUpload() {
      if (this.images.find(item => item.status !== 200)) {
        this.$dialog.alert({
          message: '有图片还在上传中，请等待上传完再操作！'
        })
        return false
      }
      return true
    },

    async submitData(params) {
        // eslint-disable-next-line no-console
        console.log(params);
        await this.$_request(networkFeedback(params))
        setTimeout(() => {
            this.$router.go(-1)
        }, 300)
    },
    async onSubmit() {
      if (this.canContinueCheckUpload() === false) return
      const params = {
        ...this.$route.query,
        description: this.description,
        images: this.images.map(item => item.guid)
      }
      window.console.warn('onSubmit', params)
      if (this.checkSubmit(params)) {
        await this.$_submitDataWidthUi(
          () => this.submitData(params),
            `确定提交反馈信息的相关内容?`,
          true
        )
      }
    },
    checkSubmit(params) {
      let message = ''
      if (params.description.length === 0) {
        message = '请填写故障情况补充说明'
      }
      if (message) {
        this.$notify({ type: 'danger', message })
        return false
      }
      return true
    },
    async afterRead(file) {
      const result = await this.uploadMix_afterRead(file)
      if (result === -1) {
        this.images.pop()
      }
    },
    fetchData() {
      const pageStorage = getPageStorage(JSON.stringify(this.$route.query))
      if (pageStorage) {
        this.description = pageStorage.description
        this.images = pageStorage.images
      }
      this.isLoading = false
    }
  },
  mounted() {
    this.fetchData()
  }
}
</script>
<style lang="scss"></style>
