<template>
  <div class="es-detail">
    <TemplateDetail
      :actions="actions"
      :coms="coms"
      v-on="{ action: onAction }"
    />
  </div>
</template>
<script>
import diyDetail from '@/mixins/diyDetail'
export default {
  name: 'Diagnosis-Detail',
  mixins: [diyDetail],
  config: {
    fetchDataFn: 'initView',
    watchRoute: true
  },
  methods: {
    onAction(btn) {
      this.$_handleCfgAction(btn)
    },
    async initView() {
      const { url, data } = this.$_extractRequestInfoFormRoute()
      const result = await this.$_request({
        url: url || '/mock/ensure-detail',
        data
      })
      this.$_initDetailPage(result)
      return result
    }
  }
}
</script>
