<template>
  <div v-show="!loading">
    <div class="list-container xa-bg-white">
      <div class="list-item" v-for="(item, index) in list" :key="index">
        <div class="list-item__title">{{ item.title }}</div>
        <div class="list-item__desc">
          <div class="list-item__desc-label">内容：</div>
          <div>{{ item.desc }}</div>
        </div>
        <div class="list-item__desc" v-if="item.images && item.images.length">
          <div class="list-item__desc-label">附图：</div>
          <EnsureImages width="65" height="65" :images="item.images" />
        </div>
        <div class="list-item__time">{{ item.time }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import basePage from '@/mixins/basePage'
import EnsureImages from '@/components/EnsureImages'
import { getClientGetLiabilityList } from '@/apis/authas'
export default {
  mixins: [basePage],
  config: {
    title: '定责分析',
    canRefresh: false,
    fetchDataFn: 'initView'
  },
  components: {
    EnsureImages
  },
  data() {
    return {
      loading: true,
      list: []
    }
  },
  methods: {
    async initView() {
      const list = await this.$_fetchDataWidthUi(
        getClientGetLiabilityList(this.$route.query)
      )
      this.list = list.map(item => {
        return {
          title: item.type,
          desc: item.content,
          time: item.time,
          images: item.images
        }
      })
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.list-container {
  .list-item {
    position: relative;
    padding: 15px 15px 10px 30px;
    overflow: hidden;
    &:after {
      content: ' ';
      position: absolute;
      top: 16px;
      left: 10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #d8d8d8;
    }
    &:before {
      content: ' ';
      position: absolute;
      left: 15px;
      top: 0;
      bottom: 0;
      background-color: #c7c7c7;
      transform: scaleX(0.5);
      width: 1px;
    }
    &:first-of-type::after {
      content: ' ';
      background-color: #ff7700;
    }
    &:first-of-type::before {
      content: ' ';
      top: 25px;
    }
    &:last-of-type::before {
      content: ' ';
      height: 20px;
      bottom: initial;
    }
    &:last-of-type .list-item__time::after {
      content: '';
      display: none;
    }
    &__title {
      margin-bottom: 12px;
      font-size: 15px;
      color: #3a3a3a;
    }
    &__desc {
      display: flex;
      margin-bottom: 12px;
      font-size: 13px;
      line-height: 1.7;
      color: #666;
    }
    &__desc-label {
      flex-shrink: 0;
    }
    &__time {
      font-size: 13px;
      color: #999;
      &::after {
        content: ' ';
        position: absolute;
        bottom: 1px;
        left: 30px;
        right: 15px;
        height: 1px;
        transform: scaleY(0.5);
        background-color: #c7c7c7;
      }
    }
  }
}
</style>
