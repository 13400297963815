<template>
  <div class="es-detail">
    <TemplateDetail :coms="coms" v-on="{ action: onAction }" />
  </div>
</template>
<script>
import diyDetail from '@/mixins/diyDetail'
export default {
  mixins: [diyDetail],
  config: {
    fetchDataFn: 'initView'
  },
  methods: {
    onAction(btn) {
      this.$_handleCfgAction(btn)
    },
    async initView() {
      const { url, data } = this.$_extractRequestInfoFormRoute()
      const result = await this.$_request({
        url: url || '/mock/eusure-diagnostic-audit',
        data
      })
      this.$_initDetailPage(result)
      return result
    }
  }
}
</script>
